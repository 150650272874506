/* eslint-disable @typescript-eslint/ban-ts-ignore */
import styled from '@emotion/styled'
import { colors } from '../styles/variables'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

export const LeftColumn = styled.div`
  padding: 60px 70px 0 60px;
  padding-bottom: ${({ isEmpty }: { isEmpty: boolean }) => (isEmpty ? '200px' : '0')};
  flex-direction: column;
  display: flex;
  @media (max-width: 1200px) {
    padding-right: 0;
  }
  @media (max-width: 750px) {
    display: none;
  }
`

export const MobileFilter = styled.div`
  padding-top: 52px;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  cursor: pointer;
  @media (min-width: 750px) {
    display: none;
  }
`

export const ModalContent = styled.div`
  position: relative;

  padding-bottom: 120px;
  height: 100%;
  @media (min-width: 750px) {
    display: none;
    z-index: -99;
  }
`

export const ModalHeader = styled.div`
  position: absolute;
  background: #1d1620;
  left: 0;
  right: 0;
  top: 0;
  height: 64px;
  width: 100%;
  display: flex;
  z-index: 2;
`

export const ModalTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
`

export const HeaderInner = styled.div`
  width: 100%;
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: center;
`

export const ModalInner = styled.div`
  position: relative;
  overflow: auto;
  padding: 32px;
  height: 100%;
`

export const ModalFooter = styled.div`
  position: absolute;
  border-top: 2px solid #3b343e;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px 32px;
  display: flex;
  justify-content: space-between;
  background: ${colors.plain.nuances1Bis};
`

export const RightColumn = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
`

export const SideElemTitle = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${colors.plain.nuances9};
`

export const InnerLabel = styled.span`
  padding-left: 12px;
`

export const ChekboxLabel = styled.label`
  color: ${colors.plain.nuances7};
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
  display: flex;
  padding-top: 16px;
  display: flex;
  align-items: center;
  transition: 200ms;
  &:hover {
    color: white;
  }
  cursor: pointer;
  & input {
    opacity: 0;
    z-index: 10;
    width: 24px;
    height: 24px;
    position: absolute;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  .inco-checkbox-displayed-fake-input {
    border: 1px solid ${colors.plain.nuances7};
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    & .inco-checkbox-strokes-wrapper {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 2px;
      right: 0;
      bottom: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transform: scale(0);
      > span {
        border-radius: 1px;
        background-color: ${colors.plain.nuances1};
      }
      > span:first-of-type {
        height: 2px;
        width: 5px;
        transform: rotate(40deg) translate(-1px, 5px);
      }
      > span:last-of-type {
        height: 2px;
        width: 8px;
        transform: rotate(-45deg);
      }
    }
  }

  input:checked + .inco-checkbox-displayed-fake-input {
    background-color: ${colors.plain.nuances10};
    border-color: ${colors.plain.nuances10};
    & .inco-checkbox-strokes-wrapper {
      transform: scale(1);
    }
  }

  input:checked ~ span {
    font-weight: bold;
    color: ${colors.plain.nuances10};
  }
  .inco-checkbox-container-disabled {
    display: flex;
    width: 100%;

    & .inco-checkbox-hidden-input {
      cursor: not-allowed;
    }

    & .inco-checkbox-displayed-fake-input {
      background-color: ${colors.plain.nuances7};
      border-color: ${colors.plain.nuances7};
    }

    & .inco-checkbox-strokes-wrapper {
      transform: scale(1);
      > span {
        background-color: ${colors.plain.nuances7};
      }
    }
  }
`

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
`

export const SlidersWrapper = styled.div`
  padding-top: 32px;
  @media (max-width: 750px) {
    max-width: 300px;
  }
`

export const SliderWrapper = styled.div`
  padding: 24px 0 40px 0;
  /* cursor: pointer;
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
  &:active {
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
  } */
`

export const SliderLabelWrapper = styled.div`
  padding-top: 16px;
`

export const SliderLabel = styled.div`
  background: ${colors.gradient.nuances2};
  border-radius: 6px;
  padding: 12px 24px;
  display: inline-block;
`

export const railStyle = {
  background: colors.gradient.nuances2,
  borderRadius: 8,
  height: 8
}

export const trackStyle = {
  background: colors.gradient.nuances10,
  borderRadius: 8,
  height: 8
}

export const handleStyle = {
  background: colors.gradient.nuances10,
  width: 24,
  height: 24,
  boxShadow: '0px 4px 12px rgba(11, 1, 11, 0.5)',
  marginTop: '-9px',
  border: 'none'
}

export const RecoGrid = styled.div`
  display: grid;
  grid-template: repeat(${({ rows }: { rows: number }) => rows}, 260px) / 1fr 1fr;
  grid-gap: 32px;
  padding: 90px;
  @media (max-width: 950px) {
    padding: 64px;
  }
  @media (max-width: 900px) {
    grid-template: 260px / 1fr;
  }
  @media (max-width: 750px) {
    padding-top: 32px;
  }
  @media (max-width: 650px) {
    padding: 32px;
  }
`

export const RecoCard = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 12px rgba(11, 1, 11, 0.5);
  border-radius: 24px;
  background: ${colors.gradient.nuances2};
  height: 260px;
  justify-content: center;
  padding: 24px 40px;
`

interface IconType {
  size?: string
  iconUrl?: string
  bgSize?: string
  borderRadius?: string
  isHeader?: boolean
}

// @ts-ignore
export const RecoIcon = styled.div`
  width: ${({ size }: IconType) => size};
  height: ${({ size }: IconType) => size};
  position: relative;
  background-image: linear-gradient(180deg, #db767c 0.01%, #a633c3 100%);
  border-radius: ${({ borderRadius }: IconType) => borderRadius};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(${({ iconURL }: IconType) => iconURL});
    background-size: ${({ bgSize }: IconType) => bgSize};
    background-repeat: no-repeat;
    background-position: center;
  }
  @media (max-width: 600px) {
    ${({ isHeader }) => (isHeader ? 'transform: scale(0.67)' : '')};
    transform-origin: left;
  }
  @media (max-width: 350px) {
    ${({ isHeader }) => (isHeader ? 'position: absolute' : '')};
    left: 0;
  }
`
export const RecoCardTitle = styled.div`
  font-family: Montserrat;
  margin-top: 24px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.plain.nuances9};
`
export const RecoCardSubtitle = styled.div`
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.plain.nuances7};
`

export const BottomRight = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 130px;
`

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
`

export const NotFoundTitle = styled.h2`
  font-family: Montserrat;
  padding-top: 16px;
  color: ${colors.plain.nuances9};
`

export const NotFoundSubtitle = styled.div`
  color: ${colors.plain.nuances7};
  padding-top: 16px;
  padding-bottom: 100px;
`
