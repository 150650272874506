/* eslint-disable import/no-named-as-default-member */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react'
import Slider from 'rc-slider'
import Modal from 'react-modal'
import 'rc-slider/assets/index.css'
import { Link, graphql } from 'gatsby'
import TextTruncate from 'react-text-truncate'
import CheckboxGroup from 'react-checkbox-group'
import SearchIcon from '../assets/search.inline.svg'
import Close from '../assets/close.inline.svg'
import Filtering from '../assets/filtering.inline.svg'

import DataContext from '../components/Common/DataContext'
import { FlatButton, ColorButton } from '../components/Common/Buttons'
import IndexLayout from '../layouts'
import {
  Container,
  LeftColumn,
  SideElemTitle,
  ChekboxLabel,
  GroupWrapper,
  InnerLabel,
  RightColumn,
  SliderLabel,
  SlidersWrapper,
  SliderWrapper,
  SliderLabelWrapper,
  trackStyle,
  railStyle,
  handleStyle,
  RecoGrid,
  RecoCard,
  RecoIcon,
  RecoCardTitle,
  RecoCardSubtitle,
  BottomRight,
  NotFoundWrapper,
  NotFoundTitle,
  NotFoundSubtitle,
  MobileFilter,
  ModalFooter,
  ModalInner,
  ModalHeader,
  HeaderInner,
  ModalTitle,
  ModalContent
} from '../style/result-style'

interface SiteMetadata {
  title: string
}

Modal.setAppElement('#___gatsby')
type Topic = 'culture' | 'hiring' | 'balance' | 'empowerment'

const engagementLabel = ['-', 'very low', 'low', 'moderate', 'high', 'very high']

const timeLabel = ['-', 'very low', 'low', 'moderate', 'high', 'very high']

const moneyLabel = ['-', 'very low', 'low', 'moderate', 'high', 'very high']

interface Data {
  site: SiteMetadata
  allMarkdownRemark: { edges: Array<{ node: Recommentation }> }
}

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: '#1D1620',
    border: 'none',
    borderRadius: 0,
    padding: 0
  },
  overlay: {
    zIndex: 99
  }
}

interface Recommentation {
  frontmatter: {
    title: string
    subtitle: string
    icon: {
      publicURL: string
    }
    topic: Topic
    money: number
    time: number
    engagement: number
  }
  fields: {
    slug: string
  }
}

interface ResultsPageProps {
  data: Data
}

const allTopics: Topic[] = ['culture', 'hiring', 'balance', 'empowerment']

const ResultsPage = ({ data }: ResultsPageProps) => {
  const contextValue = useContext(DataContext)
  const {
    topicsParam,
    handleTopicsParam,
    moneyParam,
    timeParam,
    engagementParam,
    handleMoneyParam,
    handleTimeParam,
    handleEngagementParam
  } = contextValue
  const recommendations = data?.allMarkdownRemark?.edges

  const timeCounter = timeParam > 0 ? 1 : 0
  const moneyCounter = moneyParam > 0 ? 1 : 0
  const engagementCounter = engagementParam > 0 ? 1 : 0
  const paramCounter = topicsParam.length + timeCounter + moneyCounter + engagementCounter
  const resetFilters = () => {
    handleTopicsParam(allTopics)
    handleMoneyParam(5)
    handleTimeParam(5)
    handleEngagementParam(5)
  }
  const filteredRecommendations = recommendations.filter(({ node }) => {
    const { topic, money, time, engagement } = node.frontmatter
    return (
      (money <= moneyParam || !moneyParam) &&
      (engagement <= engagementParam || !engagementParam) &&
      (time <= timeParam || !timeParam) &&
      topicsParam.includes(topic)
    )
  })
  const [isFilterModalOpen, setFilterModalOpen] = useState(false)
  const TopicFilter = [
    {
      value: 'culture',
      label: 'Company culture'
    },
    {
      value: 'hiring',
      label: 'Hiring'
    },
    {
      value: 'balance',
      label: 'Work-life Balance'
    },
    {
      value: 'empowerment',
      label: 'Employee Empowerment'
    }
  ]

  const Filters = () => (
    <>
      <GroupWrapper>
        <SideElemTitle>Topics</SideElemTitle>
        <CheckboxGroup name="topic-parameters" value={topicsParam} onChange={values => handleTopicsParam(values)}>
          {Checkbox => (
            <>
              {TopicFilter.map(elem => (
                <ChekboxLabel key={`topic_${elem.label}`}>
                  <Checkbox value={elem.value} />
                  <div className="inco-checkbox-displayed-fake-input">
                    <div className="inco-checkbox-strokes-wrapper">
                      <span />
                      <span />
                    </div>
                  </div>
                  <InnerLabel>{elem.label}</InnerLabel>
                </ChekboxLabel>
              ))}
            </>
          )}
        </CheckboxGroup>
      </GroupWrapper>
      <SlidersWrapper>
        <SideElemTitle>Time</SideElemTitle>
        <SliderLabelWrapper>
          <SliderLabel>{timeLabel[timeParam]}</SliderLabel>
        </SliderLabelWrapper>
        <SliderWrapper>
          {/* @ts-ignore */}
          <Slider
            railStyle={railStyle}
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            min={1}
            max={5}
            step={1}
            value={timeParam}
            onChange={(value: number) => handleTimeParam(value)}
          />
        </SliderWrapper>
        <SideElemTitle>People</SideElemTitle>
        <SliderLabelWrapper>
          <SliderLabel>{engagementLabel[engagementParam]}</SliderLabel>
        </SliderLabelWrapper>
        <SliderWrapper>
          {/* @ts-ignore */}
          <Slider
            railStyle={railStyle}
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            min={1}
            max={5}
            step={1}
            value={engagementParam}
            onChange={(value: number) => handleEngagementParam(value)}
          />
        </SliderWrapper>
        <SideElemTitle>Budget</SideElemTitle>
        <SliderLabelWrapper>
          <SliderLabel>{moneyLabel[moneyParam]}</SliderLabel>
        </SliderLabelWrapper>
        <SliderWrapper>
          {/* @ts-ignore */}
          <Slider
            railStyle={railStyle}
            trackStyle={trackStyle}
            handleStyle={handleStyle}
            min={1}
            max={5}
            step={1}
            value={moneyParam}
            onChange={(value: number) => handleMoneyParam(value)}
          />
        </SliderWrapper>
      </SlidersWrapper>
    </>
  )

  return (
    <IndexLayout>
      <Container>
        <LeftColumn isEmpty={!filteredRecommendations?.length}>
          <div>
            <FlatButton type="button" onClick={() => resetFilters()}>
              Reset Filters
            </FlatButton>
          </div>
          <Filters />
        </LeftColumn>
        <RightColumn>
          <MobileFilter onClick={() => setFilterModalOpen(true)}>
            <Filtering style={{ marginRight: 12 }} />
            Filters {paramCounter && <>({paramCounter})</>}
          </MobileFilter>

          {filteredRecommendations?.length > 0 ? (
            <RecoGrid rows={Math.ceil(filteredRecommendations.length / 2)}>
              {filteredRecommendations.map(({ node }) => {
                const { title, subtitle, icon } = node.frontmatter
                const { slug } = node.fields
                return (
                  <Link to={`/recommendations${slug}`} key={`reco_${title}`}>
                    <RecoCard>
                      {/* @ts-ignore */}
                      <RecoIcon iconURL={icon.publicURL} size="48px" borderRadius="120px" bgSize="50%" />
                      <RecoCardTitle>
                        <TextTruncate line={2} element="span" truncateText="…" text={title} />
                      </RecoCardTitle>
                      <RecoCardSubtitle>{subtitle}</RecoCardSubtitle>
                    </RecoCard>
                  </Link>
                )
              })}
            </RecoGrid>
          ) : (
            <NotFoundWrapper>
              <SearchIcon />
              <NotFoundTitle>No matching results</NotFoundTitle>
              <NotFoundSubtitle>Try again with other filters</NotFoundSubtitle>
            </NotFoundWrapper>
          )}
          <BottomRight>
            <FlatButton type="button" onClick={() => resetFilters()}>
              Reset Filters
            </FlatButton>
          </BottomRight>
        </RightColumn>
      </Container>
      <Modal isOpen={isFilterModalOpen} style={customStyles} contentLabel="Filters">
        <ModalContent>
          <ModalHeader>
            <HeaderInner>
              <ModalTitle>Filters</ModalTitle>
              <Close onClick={() => setFilterModalOpen(false)} style={{ cursor: 'pointer', position: 'absolute', right: 32 }} />
            </HeaderInner>
          </ModalHeader>
          <ModalInner>
            <Filters />
          </ModalInner>
          <ModalFooter>
            <FlatButton type="button" onClick={() => resetFilters()}>
              Reset Filters
            </FlatButton>
            <ColorButton type="button" onClick={() => setFilterModalOpen(false)} disabled={topicsParam.length === 0}>
              Apply
            </ColorButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </IndexLayout>
  )
}
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: { fields: { type: { eq: "recommendations" } } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            icon {
              publicURL
            }
            topic
            money
            time
            engagement
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
export default ResultsPage
